import { graphql, PageProps } from 'gatsby';
import { FC, useEffect, useState } from 'react';

import { SmartScript } from '../components/appsflyer-smart-script';
import RegisterLayout from '../components/registration/layouts/register-layout';
import DownloadApp from '../components/registration/steps/download-app';
import Seo from '../components/seo/seo';

import { isBrowser } from '../lib/helpers';
import { handleSessionStorage, useReferralLink } from '../lib/referrals';
import { getParams } from '../lib/urls';
import { CookieModal } from '../modules/cookie-modal';

const PaidLandingPage: FC<PageProps<Queries.PaidLandingPageQuery>> = ({
  data: { contentfulPaidLandingPage },
  location,
}) => {
  const { c: campaign, daveTag } = getParams(location?.search);
  const [storedCampaign, setStoredCampaign] = useState('');
  const [storedDaveTag, setStoredDaveTag] = useState('');

  useReferralLink({ campaign, daveTag });

  useEffect(() => {
    if (isBrowser) {
      handleSessionStorage({ campaign, daveTag, setStoredCampaign, setStoredDaveTag });
    }
  }, [campaign, daveTag]);

  return (
    <main>
      <SmartScript />
      <Seo
        title={contentfulPaidLandingPage?.seoMetaData?.title ?? 'Dave - Banking for Humans'}
        description={contentfulPaidLandingPage?.seoMetaData?.descriptionText ?? ''}
        meta={
          contentfulPaidLandingPage?.seoMetaData?.hideFromSearchEngines
            ? [
                {
                  name: 'robots',
                  content: 'noindex',
                },
              ]
            : []
        }
        pageName={contentfulPaidLandingPage?.seoMetaData?.pageName ?? 'download'}
      />
      <RegisterLayout data={contentfulPaidLandingPage} showMobileProgressBar={false}>
        <DownloadApp
          qrCode={{
            filename: contentfulPaidLandingPage?.qrCode?.filename ?? '',
            url: contentfulPaidLandingPage?.qrCode?.url ?? '',
          }}
          isNewUser
          campaign={storedCampaign}
          daveTag={storedDaveTag}
          headlineTitle={contentfulPaidLandingPage?.downloadHeadline ?? undefined}
          defaultHeadlineTitle="Get up to $500 in 5 minutes or less"
        />
      </RegisterLayout>
      <CookieModal />
    </main>
  );
};

export const query = graphql`
  query PaidLandingPage($slug: String) {
    contentfulPaidLandingPage(slug: { eq: $slug }) {
      seoMetaData {
        title
        descriptionText
        hideFromSearchEngines
        pageName
      }
      qrCode {
        filename
        url
      }
      slug
      termsLinkButton {
        path
        text
      }
      downloadHeadline
      valuesPanelTitle
      valuesPanelItems {
        itemHeading
        itemSubheading
        itemBulletImage {
          filename
          url
          description
        }
      }
    }
  }
`;

export default PaidLandingPage;
